@media print {
    @page {
        margin: 5px 5px 0px 0px;
        size: 56mm auto;
    }
    body {
        -webkit-print-color-adjust: exact;
        print-color-adjust: exact;
        margin: 0;
        font-family: 'Arial', sans-serif;
        visibility: hidden;
    }
    #struk-order {
        position: absolute;
        left: 0;
        top: 0;
        width: 56mm; /* Ensure the width matches your printer's paper size */
        max-width: 56mm;
        font-size: 12px;
        font-family: Arial, sans-serif;
      }
    #struk-order, #struk-order * {
        visibility: visible;
      }
}
